import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/icons/O&R_Admin_Logo_1.png";
import { FaFacebookSquare, FaTwitterSquare } from "react-icons/fa";
import clock from "../../assets/clock (5).png";
import cloud from "../../assets/cloud (1).png";
import { FaSquareInstagram } from "react-icons/fa6";
import { useSelector } from "react-redux";

const Footer = () => {
  const { menuList } = useSelector((state) => state.publicReducer);
  // console.log("menuListfooter", menuList?.data);

  // const pollsLinks = [
  //   {
  //     id: 1,
  //     name: "US Polls",
  //     link: "",
  //   },
  //   {
  //     id: 2,
  //     name: "UK Polls",
  //     link: "",
  //   },
  //   {
  //     id: 3,
  //     name: "Canada Polls",
  //     link: "",
  //   },
  //   {
  //     id: 4,
  //     name: "Australia Polls",
  //     link: "",
  //   },
  //   {
  //     id: 5,
  //     name: "Canada Polls",
  //     link: "",
  //   },
  //   {
  //     id: 6,
  //     name: "India Polls",
  //     link: "",
  //   },
  // ];

  const quickLinks = [
    {
      id: 1,
      name: "About Us",
      link: "/about_us",
    },
    {
      id: 2,
      name: "Contact Us",
      link: "/contact_us",
    },
    {
      id: 3,
      name: "FAQ",
      link: "/faq",
    },
    {
      id: 4,
      name: "Terms of Service",
      link: "/term_of_service",
    },
    {
      id: 5,
      name: "Privacy Policy",
      link: "/privacy_policy",
    },
    {
      id: 6,
      name: "Refund Policy",
      link: "/refund_policy",
    },
  ];

  const getTemperature = () => {
    // In a real-world application, you would fetch this data from a weather API.
    return "38°C";
  };
  const [date, setDate] = useState(new Date());
  const [temperature, setTemperature] = useState(getTemperature());

  useEffect(() => {
    // Update date every second
    const timer = setInterval(() => {
      setDate(new Date());
    }, 1000);

    // Update temperature periodically if needed
    const temperatureTimer = setInterval(() => {
      setTemperature(getTemperature());
    }, 60000); // update every 60 seconds

    return () => {
      clearInterval(timer);
      clearInterval(temperatureTimer);
    };
  }, []);

  return (
    <footer className="w-full bg-[#012169] text-white p-5 md:px-40 md:py-5 flex flex-col ">
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 pb-7">
        <div className="flex items-center justify-center md:items-start md:justify-start  ">
          <h2 className="text-xl font-semibold text-white">
            <img src={logo} alt="logo" className="h-[50%] w-[50%]" />
          </h2>
        </div>
        <div className="flex flex-row justify-around md:justify-between mx-4 md:mx-0 items-start">
          <div className="flex md:flex-col justify-center sm:justify-end">
            <div className="flex flex-col">
              <h4 className=" font-semibold text-[14px] text-[#ffffff]">
                Home
              </h4>
              <ul className="flex flex-col gap-2 mt-4">
                {Array.isArray(menuList?.data) &&
                  menuList?.data.map((item) => (
                    <li
                      key={item?.id}
                      onClick={() => {
                        sessionStorage.setItem("redirectLink", item?.slug);
                      }}
                    >
                      <Link
                        to={`/${item?.slug}/general-stories`}
                        className="text-[#ffffff] hover:text-white text-[14px]"
                      >
                        {item?.name}
                      </Link>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
          <div className="flex md:flex-col justify-center sm:justify-end">
            <div className="flex flex-col">
              <h4 className=" font-semibold text-[14px] text-[#ffffff]">
                Quick Links
              </h4>
              <ul className="flex flex-col gap-2 mt-4">
                {quickLinks.map((item) => (
                  <li key={item.id}>
                    <Link
                      to={item.link}
                      className="text-[#ffffff] hover:text-white text-[14px]"
                    >
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <hr
        className="w-full border-t-2 border-white bg-white"
        style={{ opacity: 0.6 }}
      />
      <div className="flex flex-row px-4 py-2 items-center justify-between  text-gray-400">
        <div className="flex flex-row items-center justify-center gap-2 md:gap-10 mb-2 md:mb-0">
          <div className="flex items-center justify-center gap-2 text-white font-semibold text-[14px]">
            {/* <MdOutlineWbSunny /> */}
            <img src={cloud} alt="no clock" height={16} width={16} />

            {temperature}
          </div>
          <div className="flex items-center justify-center gap-2 text-white font-semibold text-[14px]">
            {/* <FaRegClock /> */}
            <img src={clock} alt="no clock" height={16} width={16} />
            {date.toLocaleDateString("en-US", {
              year: "numeric",
              month: "short",
              day: "numeric",
            })}
          </div>
        </div>
        <div className="flex items-center gap-2">
          <span className="text-white font-semibold text-[14px]">
            Follow us
          </span>
          <a
            href="https://facebook.com"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white"
          >
            <FaFacebookSquare />
          </a>
          <a
            href="https://twitter.com"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white"
          >
            <FaTwitterSquare />
          </a>
          <a
            href="https://instagram.com"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white"
          >
            <FaSquareInstagram />
          </a>
        </div>
      </div>
      <hr
        className="w-full border-t-2 border-white bg-white"
        style={{ opacity: 0.6 }}
      />

      <div className="text-center pt-7">
        <span className="text-[14px]">
          &copy; 2024 Political Pulse. All Rights Reserved.
        </span>
      </div>
    </footer>
  );
};

export default Footer;
