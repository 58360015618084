import { FaSearch } from "react-icons/fa";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { globalSearch } from "../../redux/actions/publicAction";
import searchoptionarrow from "../../assets/icons/searchoptionarrow.svg";
import { Link, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

function SearchModel({
  title,
  message,
  handleConfirm,
  handleCloseModal,
  confirmBtn,
  cancelBtn,
  handleSelect,
  setSearchModel,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [isToast, setIsToast] = useState(false);
  const { globalsearch } = useSelector((state) => state.publicReducer);

  const handleSearch = (e) => {
    const value = e.target.value;
    // console.log(" value ", value);

    setSearch(value);
  };

  useEffect(() => {
    if (search.length >= 2) {
      // localStorage.setItem("search",search)
      dispatch(globalSearch(search));
    }
  }, [search]);

  const createSlug = (title) =>
    title
      .toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/[^\w-]+/g, "");

  const handleSubmit = () => {
    // console.log("search", search);
    localStorage.setItem("search", search);
    if (search?.length > 0) {
      dispatch(globalSearch(search));
      navigate(`/search/search-result/${search}`);
      setSearchModel(false);
    } else {
      setIsToast(true);
      setTimeout(() => {
        setIsToast(false);
      }, 2000);
      // toast.error("Enter at least  one character");
    }
  };
  const handleSuggest = (queary) => {
    // console.log("search", search);
    localStorage.setItem("search", queary);
    if (search?.length > 0) {
      dispatch(globalSearch(queary));
      navigate(`/search/search-result/${queary}`);
      setSearchModel(false);
    } else {
      setIsToast(true);
      setTimeout(() => {
        setIsToast(false);
      }, 2000);
      // toast.error("Enter at lceast  one character");
    }
  };

  return (
    <div
      className="absolute inset-0 top-[68px] flex bg-gray-900 bg-opacity-50 z-20 justify-center md:px-40"
      onClick={handleCloseModal}
    >
      <Toaster />
      <div
        className="bg-white my-4 md:my-0 rounded-lg md:rounded-t-none shadow-lg p-6 mx-4 md:mx-0 w-full relative h-auto md:h-[25rem]"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex flex-row gap-4 text-center">
          <div className="border-2 border-[#BBC5D5] rounded-[10px]  items-center w-full overflow-auto bg-white">
            <div className="flex flex-col w-full">
              <div
                className={`flex flex-row items-center px-2 py-1 rounded-lg ${
                  search ? "bg-gray-200" : "bg-white"
                }`}
              >
                <FaSearch />
                <div className="h-[23px] w-[1px] bg-gray-300 mx-2" />
                <input
                  type="search"
                  placeholder="Search Keyword"
                  className="border-none outline-none text-xs bg-transparent rounded-md py-1 w-full"
                  value={search}
                  onChange={handleSearch}
                />
              </div>
              {search && (
                <ul className="max-h-60 mt-1 text-start  ">
                  {Array.isArray(globalsearch) && globalsearch.length > 0
                    ? globalsearch.slice(0, 5).map((option, index) => (
                        <li
                          key={index}
                          className="py-2 px-4 hover:bg-gray-200 cursor-pointer flex items-center"
                          onClick={() => handleSuggest(option?.title)} // Ensure option is selected
                        >
                          <div className="flex items-center">
                            <img
                              src={searchoptionarrow}
                              alt="search-icon"
                              className="h-2 w-2 mr-2"
                            />
                            {option?.title}
                          </div>
                        </li>
                      ))
                    : search.length >= 3 && (
                        <li className="py-2 px-4 text-gray-500">
                          No results found
                        </li>
                      )}
                </ul>
              )}
            </div>
          </div>
          <div className="flex flex-col  ">
            <button
              onClick={handleSubmit}
              className="bg-[#FF3D00] text-white px-6 py-1 rounded-lg"
            >
              {confirmBtn}
            </button>
          </div>
        </div>
        {isToast && (
          <span className="text-red-500 font-semibold text-xs">
            Enter at least a character !
          </span>
        )}
        <div className="flex flex-col gap-2 py-4">
          <div className="">Trending Topics</div>
          <div className="flex flex-row gap-5 flex-wrap">
            <div
              // key={ele?.id}
              className="border rounded-full px-5 py-3 font-bold cursor-pointer border-[#E2E7EE]"
            >
              <div className="text-[10px] text-gray-600">NarendraModi</div>
            </div>
            <div
              // key={ele?.id}
              className="border rounded-full px-5 py-3 font-bold cursor-pointer border-[#E2E7EE]"
            >
              <div className="text-[10px] text-gray-600">
                Arvind Kejriwal Bail
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SearchModel;
